import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import AdditionalForms from "../components/common/AdditionalForms"
import loadable from "@loadable/component"
const IndexBelowTheFold = loadable(() => import("../components/homepage/IndexBelowTheFold"))

const IndexPage = () => {
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        canonical={"/"}
        title="Sell Your Jewelry - Top Buyer Of Luxury Jewelry &amp; Timepieces"
        description="Sell your Jewelry & Luxury Watches. Top Rated Buyer of Pre-Owned Rolex, Cartier, Patek Phillipe, Tiffany, Audeamars and Piguet. Visit our website today!"
      />
      <section className="bg-[#f6ebd0] ">
        <div className="w-10/12 items-center max-w-[1366px] mx-auto flex flex-col md:flex-row  ">
          <div className="mt-16 md:mt-0 aspect-square xl:max-w-[350px]">
            <StaticImage
              className={"object-contain w-full"}
              loading={"lazy"}
              placeholder="blurred"
              src={"../images/sell-us-your-jewelry-banner.png"}
              alt={"Watches and Jewelry Image"}
            />
          </div>

          <div className="padding-top: 5px leading-tight lg:px-10 xl:text-3xl   pb-5 md:pb-0 md:pt-5">
            <h1 className="text-center text-2xl font-black text-link xl:text-3xl padding-bottom: 0.25rem">
              Best Place To Sell Your Jewelry
            </h1>
            <div className="text-link text-center text-lg xl:text-xl font-normal leading-tight line-height: 2.25rem ">
              Sell your luxury watches, designer brand or fine jewelry, today! We are the world{"'"}
              s leading buyer with over 40 years of experience in buying, trading, repairing and
              consigning luxury watches and top designer brand jewelry; Rolex, Audemars Piguet,
              Patek Philippe, Tiffany & Co., Van Cleef & Arpels, Cartier, and more.
            </div>
          </div>
        </div>
      </section>
      <AdditionalForms />
      <IndexBelowTheFold fallback={<div>Loading...</div>} />
    </Layout>
  )
}

export default IndexPage
